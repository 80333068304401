import { useEffect } from "react"
import {
  useLocation,
} from "react-router-dom";
import { usePaymentStore } from "../stores/payment";
import toast from "react-hot-toast";

export const useDiscountQueryParams = () => {
  const { search,pathname } = useLocation()
  const { setDiscountCode, setOpenPaymentModal } = usePaymentStore()

  const getSearchParams = () => {
    const queryParams = new URLSearchParams(search)
    return queryParams.get('discountCode')

  }

  useEffect(() => {
    const discountCode = getSearchParams()
    if (discountCode) {
      setDiscountCode(discountCode);
      // setOpenPaymentModal(true);
      if (pathname !== '/ai-photo') {
        setOpenPaymentModal(true);
        toast.success('Promo code applied - choose an option to activate discount');
      }
      if (pathname == '/ai-photo') {
        toast.success('Promo code applied - proceed to checkout to activate discount');
      }
    }
  }, [])
}