import { useEffect } from "react";
import Slider from "react-slick";
import Image1 from "../../assets/images/ai-1.png";
import Image2 from "../../assets/images/ai-2.png";
import Image3 from "../../assets/images/ai-3.png";
import Image4 from "../../assets/images/ai-4.png";
import { Helmet } from "react-helmet-async";
import { useLogEvent } from "../../analytics";
import { PrimaryButton } from "../../components/PrimaryButton";
import { useAIPhotosStore } from "../../stores/aiphotos";
import { useAIPhotosResults } from "./useAIPhotosResults";
import { FamewallReviews } from "../FamewallReviews";

const settings = {
  dots: true,
  infinite: true,
  autoplay: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
  arrows: false,
};

const treatmentSettings = {
  dots: false,
  infinite: true,
  autoplay: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
  arrows: false,
};

const images = [Image1, Image2, Image3, Image4];

type Props = {
  onNextPress: () => void;
};

export const AIPhotosLanding = ({ onNextPress }: Props) => {
  const { aiPhotosEmail } = useAIPhotosStore();
  const { setAIPhotosWizardComplete } = useAIPhotosStore();
  const { fetchAIPhotoBatches, loadingBatches } = useAIPhotosResults();

  const checkBatches = async () => {
    if (!aiPhotosEmail) {
      setAIPhotosWizardComplete(false);
    } else {
      const batches = await fetchAIPhotoBatches();
      setAIPhotosWizardComplete(batches.length > 0);
    }
  }

  useEffect(() => {
    checkBatches()
  }, []);

  useLogEvent("start", "photos");

  return (
    <div className="mx-auto max-w-xl flex flex-col justify-center">
      <div className="flex flex-col">
        <Helmet>
          <meta name="title" content="AI Enhanced Dating Photos" />
          <meta
            name="description"
            content="Get studio quality AI photos for your dating profile at 1/10th the cost."
          />
        </Helmet>
        <div className="flex items-center text-center justify-center my-4 px-10 mt-6">
          <h1 className="text-2xl font-bold mx-4">
            Stand out with
            <br />
            AI-Enhanced Photos
          </h1>
        </div>
        <div className="flex items-center justify-center text-center">
          <p className="text-sm font-semibold">
            💕 Double your swipes with more charming you
          </p>
        </div>
        <div className="flex items-center justify-center text-center">
          <p className="text-sm text-[#999999]">
            Look your best while staying true to who you are.
          </p>
        </div>
        <div className="items-center text-center my-8 mx-12 max-w-full">
          <Slider {...settings}>
            {images.map((image) => {
              return (
                <div className="items-center">
                  <img
                    className="items-center"
                    alt="profile photo improved using AI"
                    src={image}
                  />
                </div>
              );
            })}
          </Slider>
        </div>
        <div className="mt-4 mx-4">
          <h4 className="font-semibold text-sm md:text-md mb-2">
            What's included:
          </h4>
          <ul className="list-none space-y-1">
            <li className="flex items-center text-sm md:text-md">
              ✅ Show your best self
            </li>
            <li className="flex items-center text-sm md:text-md">
              ✅ 90 photos in 15 different backgrounds & styles
            </li>
            <li className="flex items-center text-sm md:text-md">
              ✅ Up to 44% more matches on average
            </li>
          </ul>
          <h4 className="font-semibold text-sm md:text-md mt-4 mb-2">
            What we won't do:
          </h4>
          <ul className="list-none space-y-1">
            <li className="flex items-center text-sm md:text-md">
              ❌ Create fake-looking photos
            </li>
            <li className="flex items-center text-sm md:text-md">
              ❌ Make you taller or give you a dog
            </li>
          </ul>
        </div>
        <PrimaryButton
          loadingTitle={loadingBatches ? "Loading..." : undefined}
          disabled={loadingBatches}
          containerClass="m-4 mt-8 p-4 mb-32"
          onClick={onNextPress}
          title="Enhance my photos"
        />
      </div>
      <div className="relative w-screen left-1/2 right-1/2 -mx-[50vw]">
        <div className="flex justify-center w-full">
          <FamewallReviews containerStyle="px-2" title="Testimonials" />
        </div>
      </div>
    </div>
  );
};

export const AIPhotosLandingTreatment = ({ onNextPress }: Props) => {
  const { aiPhotosEmail } = useAIPhotosStore();
  const { setAIPhotosWizardComplete } = useAIPhotosStore();
  const { fetchAIPhotoBatches, loadingBatches } = useAIPhotosResults();

  const checkBatches = async () => {
    if (!aiPhotosEmail) {
      setAIPhotosWizardComplete(false);
    } else {
      const batches = await fetchAIPhotoBatches();
      setAIPhotosWizardComplete(batches.length > 0);
    }
  }

  useEffect(() => {
    checkBatches()
  }, []);

  useLogEvent("start", "photos_treatment");

  return (
    <div className="w-full bg-white">
      <div className="mx-auto max-w-xl flex flex-col">
        <Helmet>
          <meta name="title" content="Professional Dating Photos" />
          <meta
            name="description"
            content="Get studio quality photos for your dating profile in minutes."
          />
        </Helmet>

        {/* Main viewport section */}
        <div className="flex flex-col justify-center h-[calc(100vh-136px)]">
          {/* Photos Section - remove flex-1 to prevent expansion */}
          <div className="items-center text-center mx-6 pt-8 max-w-full">
            <Slider {...treatmentSettings}>
              {images.map((image, index) => (
                <div className="items-center h-full" key={index}>
                  <img
                    className="items-center object-contain h-full w-full rounded-2xl"
                    alt="professional dating profile photo"
                    src={image}
                  />
                </div>
              ))}
            </Slider>
          </div>

          {/* Content Section - tighter spacing */}
          <div className="mt-6 mb-6 px-10">
            <div className="flex flex-col items-center text-center justify-center">
              <span className="text-brand-primary text-sm font-semibold tracking-wider mb-1">
                AI PHOTOSHOOT
              </span>
              <h1 className="text-2xl font-bold">
                Get studio quality photos for your dating profile
              </h1>
            </div>

            <div className="flex items-center justify-center text-center mt-3">
              <p className="text-base text-gray-600 max-w-md">
                Use AI to generate authentic photos, optimized get you more dates, and find the one.
              </p>
            </div>

            <div className="mt-6">
              <PrimaryButton
                loadingTitle={loadingBatches ? "Loading..." : undefined}
                disabled={loadingBatches}
                containerClass="w-full p-6 text-base rounded-[32px] bg-gradient-to-b from-brand-primary to-[#FF8A65]"
                onClick={onNextPress}
                title="get started"
              />
            </div>
          </div>
        </div>

        {/* Features Section - below the fold */}
        <div className="mx-4 py-10 mt-10">
          <h4 className="font-semibold text-sm md:text-md mb-2">
            What's included:
          </h4>
          <ul className="list-none space-y-1">
            <li className="flex items-center text-sm md:text-md">
              ✅ Professional looking photos true to who you are
            </li>
            <li className="flex items-center text-sm md:text-md">
              ✅ 164% more matches on average
            </li>
            <li className="flex items-center text-sm md:text-md">
              ✅ Variety of outfits, backgrounds and styles
            </li>

          </ul>

          <h4 className="font-semibold text-sm md:text-md mt-4 mb-2">
            What we won't do:
          </h4>
          <ul className="list-none space-y-1">
            <li className="flex items-center text-sm md:text-md">
              ❌ Create fake-looking photos
            </li>
            <li className="flex items-center text-sm md:text-md">
              ❌ Make you taller or give you a dog
            </li>
          </ul>
        </div>
        <div className="relative w-screen left-1/2 right-1/2 -mx-[50vw]">
          <div className="flex justify-center w-full">
            <FamewallReviews containerStyle="px-2" title="Testimonials" />
          </div>
        </div>
      </div>
    </div>
  );
};