import { useState, useRef } from "react";
import { FaChevronLeft, FaUnlock } from "react-icons/fa";
import { AIPhotoDetailView } from "./AIPhotoDetailView";
import { useDownloadImages } from "./useDownloadImages";

type Props = {
  imageUrls: string[];
  lockedPhotos: number;
  onBackClick: () => void;
  onUnlockClick: () => void;
};

type SelectedPhotoType = {
  url: string;
  index: number;
};

// Get the lockedPhotos count from batch result which will identify if the user has any locked photos in the batch
// Use this parameter to apply watermark on last N photos
export const AIPhotosBatchDetail = ({ imageUrls, lockedPhotos, onBackClick, onUnlockClick }: Props) => {
  // Create unlockedPhotos array by removing the last N photos based on lockedPhotos
  const unlockedPhotos = lockedPhotos ? imageUrls.slice(0, -lockedPhotos) : imageUrls;

  const [selectedImage, setSelectedImage] = useState<
    SelectedPhotoType | undefined
  >(undefined);
  const [scrollPosition, setScrollPosition] = useState(0);
  const containerRef = useRef<HTMLDivElement>(null);

  const { downloadAllImages } = useDownloadImages();

  const handleImageBackClick = () => {
    const currentScroll = scrollPosition;
    setSelectedImage(undefined);

    requestAnimationFrame(() => {
      const scrollContainer = containerRef.current?.closest(".overflow-y-auto");
      if (scrollContainer) {
        scrollContainer.scrollTop = currentScroll;
      }
    });
  };

  const handleImageClick = (imageUrl: string, index: number) => {
    const scrollContainer = containerRef.current?.closest(".overflow-y-auto");
    if (scrollContainer) {
      setScrollPosition(scrollContainer.scrollTop);
    }
    setSelectedImage({ url: imageUrl, index });
  };

  const handleNextImage = () => {
    if (selectedImage && selectedImage.index < imageUrls.length - 1) {
      const index = selectedImage.index + 1;
      setSelectedImage({ url: imageUrls[index], index });
    }
  };

  const handlePreviousImage = () => {
    if (selectedImage && selectedImage.index > 0) {
      const index = selectedImage.index - 1;
      setSelectedImage({ url: imageUrls[index], index });
    }
  };

  const handleUnlockClick = () => {
    console.log("Unlock 100 more photos for $20");
    handleImageBackClick();
    onUnlockClick();
  }

  return (
    <div className="flex flex-col h-full max-w-lg">
      {selectedImage ? (
        <AIPhotoDetailView
          imageUrl={selectedImage.url}
          imageIndex={selectedImage.index}
          lockedPhotos={lockedPhotos}
          totalImages={imageUrls.length}
          onBackClick={handleImageBackClick}
          onNextClick={handleNextImage}
          onPreviousClick={handlePreviousImage}
          onUnlockClick={handleUnlockClick}
        />
      ) : (
        <>
          <div className="flex justify-between items-center py-4">
            <div className="flex items-center">
              <button onClick={onBackClick} className="text-gray-400">
                <FaChevronLeft size={24} />
              </button>
              {/* <h1 className="text-lg font-semibold ml-2">{photoName}</h1> */}
            </div>
            <button
              className="text-red-500 text-sm font-semibold"
              onClick={() => downloadAllImages(unlockedPhotos)}
            >
              Download All
            </button>
          </div>

          <div className="flex-1 p-1" ref={containerRef}>
            <div className="grid grid-cols-2 gap-4">
              {unlockedPhotos.map((imageUrl, index) => {
                return (
                  <div
                    key={index}
                    className="relative w-full h-full rounded-xl overflow-hidden"
                    onClick={() => handleImageClick(imageUrl, index)}
                  >
                    <img
                      src={imageUrl}
                      alt={`Generated Photo for ${imageUrl}`}
                      className="w-full h-full object-cover rounded-xl"
                    />
                  </div>
                );
              })}
            </div>

            {lockedPhotos > 0 && <>
              <div 
                className="flex items-center justify-between px-6 py-3 my-4 bg-gradient-to-b from-brand-primary to-[#FF8A65] rounded-xl text-white text-l cursor-pointer"
                onClick={() => onUnlockClick()}>
                <div className="flex items-center gap-2">
                  <FaUnlock className="text-xl" />
                  <span>Unlock 100 more photos</span>
                </div>
                <div className="flex items-center gap-2">
                  <span className="text-sm line-through opacity-75">$49</span>
                  <span className="font-semibold">$20</span>
                </div>
              </div>
              <div className="grid grid-cols-2 gap-4">
                {imageUrls.map((imageUrl, index) => {
                  const isImageLocked = index >= imageUrls.length - lockedPhotos;
                  return (
                    isImageLocked &&
                    <div
                      key={index}
                      className="relative w-full h-full rounded-xl overflow-hidden"
                      onClick={() => handleImageClick(imageUrl, index)}
                    >
                      <img
                        src={imageUrl}
                        alt={`Generated Photo for ${imageUrl}`}
                        className="w-full h-full object-cover rounded-xl blur-[3px]"
                        onContextMenu={(e) => e.preventDefault()}
                      />
                      <div className="absolute inset-0 flex items-center justify-center bg-black opacity-30">
                        <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="100%" height="100%" viewBox="0 0 64 64" fill="white" opacity="0.6">
                          <path d="M 32 9 C 24.832 9 19 14.832 19 22 L 19 27.347656 C 16.670659 28.171862 15 30.388126 15 33 L 15 49 C 15 52.314 17.686 55 21 55 L 43 55 C 46.314 55 49 52.314 49 49 L 49 33 C 49 30.388126 47.329341 28.171862 45 27.347656 L 45 22 C 45 14.832 39.168 9 32 9 z M 32 13 C 36.963 13 41 17.038 41 22 L 41 27 L 23 27 L 23 22 C 23 17.038 27.037 13 32 13 z M 21 31 L 43 31 C 44.105 31 45 31.895 45 33 L 45 49 C 45 50.105 44.105 51 43 51 L 21 51 C 19.895 51 19 50.105 19 49 L 19 33 C 19 31.895 19.895 31 21 31 z"></path>
                        </svg>
                      </div>
                    </div>
                  );
                })}
              </div>
            </>}
          </div>
        </>
      )}
    </div>
  );
};
