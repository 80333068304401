import { Helmet } from "react-helmet-async";

export const AIPhotosDemoReady = () => {
  return (
    <>
      <Helmet>
        <meta name="robots" content="noindex" />
      </Helmet>
      <div className="flex flex-col items-center min-h-screen max-w-lg mx-auto px-4 md:px-0 text-center py-48 bg-white">
        <h1 className="text-3xl font-bold mb-4">Your photoshoot is ready!</h1>
        <p className="text-gray-600 text-xl mb-10 pt-6">We've generated 100 photos for you in 25 different styles</p>
        <button 
          className="bg-gradient-to-b from-brand-primary to-[#FF8A65] text-white px-8 py-3 rounded-full font-semibold transition-all"
        >
          DISCOVER NOW
        </button>
      </div>
    </>
  );
}; 