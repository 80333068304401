import { create } from "zustand";
import { persist } from "zustand/middleware";

export enum AIPhotosWizardStep {
  USER_INFORMATION = 1,
  EMAIL_INPUT = 2,
  UPLOAD_IMAGES = 3,
  PREVIEW_IMAGES = 4,
}

export enum AIPhotosWizardStep_Treatment {
  USER_INFORMATION = 1,
  UPLOAD_IMAGES = 2,
  EMAIL_INPUT = 3,
  PREVIEW_IMAGES = 4,
}

// Helper to get the correct steps based on variant
export const getAIPhotosWizardStep = (isTreatment: boolean) =>
  isTreatment ? AIPhotosWizardStep_Treatment : AIPhotosWizardStep;

type AIPhotosWizardStepResults = {
  gender: string;
  ethnicity: string;
  age: string;
  eyeColor: string;
};

interface AIPhotosStore {
  aiPhotosWizardComplete: boolean;
  setAIPhotosWizardComplete: (aiPhotosWizardComplete: boolean) => void;

  aiPhotosWizardStep: AIPhotosWizardStep | AIPhotosWizardStep_Treatment;
  setAIPhotosWizardStep: (
    aiPhotosWizardStep: AIPhotosWizardStep | AIPhotosWizardStep_Treatment
  ) => void;

  gender: string;
  ethnicity: string;
  age: string;
  eyeColor: string;

  aiPhotosEmail: string;
  setAIPhotosEmail: (email: string) => void;

  setGender: (gender: string) => void;
  setEthnicity: (ethnicity: string) => void;
  setAge: (age: string) => void;
  setEyeColor: (eyeColor: string) => void;

  filesToUpload: File[];
  setFilesToUpload: (filesToUpload: File[]) => void;
  clearFilesToUpload: () => void;
  removeFile: (index: number) => void;

  aiPhotosWizardStepResults: AIPhotosWizardStepResults | {};
  setAIPhotosWizardStepResults: (
    aiPhotosWizardStepResults: AIPhotosWizardStepResults
  ) => void;

  generatingAIPhotos: boolean;
  setGeneratingAIPhotos: (generatingAIPhotos: boolean) => void;

  bypassPaywall: number | undefined;
  setBypassPaywall: (bypassPaywall: number | undefined) => void;
}

export const useAIPhotosStore = create(
  persist<AIPhotosStore>(
    (set) => ({
      aiPhotosWizardComplete: false,
      setAIPhotosWizardComplete: (aiPhotosWizardComplete: boolean) =>
        set({ aiPhotosWizardComplete }),

      aiPhotosWizardStep: AIPhotosWizardStep.USER_INFORMATION,
      setAIPhotosWizardStep: (
        aiPhotosWizardStep: AIPhotosWizardStep | AIPhotosWizardStep_Treatment
      ) => set({ aiPhotosWizardStep }),

      gender: "",
      ethnicity: "",
      age: "",
      eyeColor: "",

      setGender: (gender: string) =>
        set((state) => ({
          gender,
          aiPhotosWizardStepResults: {
            ...state.aiPhotosWizardStepResults,
            gender,
          },
        })),
      setEthnicity: (ethnicity: string) =>
        set((state) => ({
          ethnicity,
          aiPhotosWizardStepResults: {
            ...state.aiPhotosWizardStepResults,
            ethnicity,
          },
        })),
      setAge: (age: string) =>
        set((state) => ({
          age,
          aiPhotosWizardStepResults: {
            ...state.aiPhotosWizardStepResults,
            age,
          },
        })),
      setEyeColor: (eyeColor: string) =>
        set((state) => ({
          eyeColor,
          aiPhotosWizardStepResults: {
            ...state.aiPhotosWizardStepResults,
            eyeColor,
          },
        })),

      aiPhotosEmail: "",
      setAIPhotosEmail: (email: string) => set({ aiPhotosEmail: email }),

      filesToUpload: [],
      setFilesToUpload: (filesToUpload: File[]) => set({ filesToUpload }),
      clearFilesToUpload: () => set({ filesToUpload: [] }),
      removeFile: (index: number) =>
        set((state) => ({
          filesToUpload: state.filesToUpload.filter((_, i) => i !== index),
        })),

      aiPhotosWizardStepResults: {},
      setAIPhotosWizardStepResults: (
        aiPhotosWizardStepResults: AIPhotosWizardStepResults
      ) => set({ aiPhotosWizardStepResults }),

      generatingAIPhotos: false,
      setGeneratingAIPhotos: (generatingAIPhotos: boolean) =>
        set({ generatingAIPhotos }),

      bypassPaywall: undefined,
      setBypassPaywall: (bypassPaywall: number | undefined) =>
        set({ bypassPaywall }),
    }),
    {
      name: "ai-photos-storage",
      getStorage: () => localStorage,
      partialize: (state) => ({
        ...state,
        hasPurchasedAIPhotos: false,
        checkingHasPaid: false,
        generatingAIPhotos: false,
        filesToUpload: [],
        aiPhotosWizardStep: AIPhotosWizardStep.USER_INFORMATION,
      }),
    }
  )
);
