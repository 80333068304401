import { useEffect, useRef, useState } from "react";
import { Helmet } from "react-helmet-async";
import { Loading } from "../../components/Loading";
import { PremiumProductsCarousel } from "../../components/payment/paywalls/PremiumProductsCarousel";
import { PremiumProducts } from "../../components/payment/paywalls/PremiumProducts";
import { PlanType } from "../../constants/payments";
import { EventParams, logEvent, useLogEvent } from "../../analytics";
import { Success } from "../../components/Success";
import { PremiumState } from "../../models/payment";
import { useAuthStore } from "../../stores/auth";
import { CheckCircleIcon } from "@heroicons/react/24/outline";
import {
  ANNUAL_PRICE,
  ANNUAL_PRICE_PREMIUM,
  CONVERSION_FACTOR_ANNUAL,
  CONVERSION_FACTOR_MONTHLY,
  MONTHLY_PRICE,
  MONTHLY_PRICE_PREMIUM,
} from "./paymentsConfig";
import { SubscriptionForm } from "../../components/premium/SubscriptionForm";
import { useWizardStore } from "../../stores/wizard";
import { auth } from "../../firebase";
import { checkUserSubscription } from "../../queries";
import { sleep } from "../../utils";
import toast from "react-hot-toast";
import { YourMoveHeader } from "../../components/YourMoveHeader";
import { usePaymentStore } from "../../stores/payment";
import { FamewallReviews } from "../FamewallReviews";
import { usePostHog } from "posthog-js/react";
import { addToMailingList, updateMailcoachSubscriber } from "../../queries";
import { getProductTypeFromPath } from "../../utils";
import { FeaturesModal } from "../../components/modals/FeaturesModal";
import { ProductType } from "../../models/payment";
import { PremiumFeatures } from "../../components/payment/paywalls/PremiumFeatures";

export const PremiumV2 = ({
  product = "chat_assistant",
  onDismiss,
  onComplete,
  showNoThanks = false,
}: PremiumState) => {
  const {
    setAuthModalIsOpen,
    setIsSubscribed,
    setShowAuthSubscriptionDisclaimer,
    setAuthModalDismissable,
  } = useAuthStore();
  const { isSubscribed } = useAuthStore();
  const { planType, setPlanType, selectedPlan, discountCode } =
    usePaymentStore();
  const { setProfileWriterWizardComplete, email } = useWizardStore();

  const postHog = usePostHog();

  const [paymentSuccess, setPaymentSuccess] = useState(false);
  const [isLoading] = useState(false);
  const emailRef = useRef(email);

  const featureFlag = postHog?.getFeatureFlag("show-sale");
  const showPremiumPrices =
    postHog?.getFeatureFlag("premium-prices-new") === "test";

  useLogEvent("premium_page_view", product);

  useEffect(() => {
    logEvent("premium_page_view", product, email, {}, "funnel");
    postHog?.capture("premium_page_view", { product });
  }, [product]);

  useEffect(() => {
    if (paymentSuccess) {
      setTimeout(() => {
        if (!auth?.currentUser) {
          setShowAuthSubscriptionDisclaimer(true);
          setAuthModalDismissable(false);
          setAuthModalIsOpen(true);
        }
        onDismiss?.();
        onComplete?.();
      }, 2750);
    }
  }, [paymentSuccess]);

  useEffect(() => {
    emailRef.current = email;
  }, [email]);

  const onMonthlyPress = () => {
    setPlanType(PlanType.Monthly);
    const params: EventParams = {
      amount: `${showPremiumPrices ? MONTHLY_PRICE_PREMIUM : MONTHLY_PRICE}`,
      payment_type: "monthly",
    };

    if (featureFlag !== undefined) {
      params["show_sale"] = featureFlag;
    }
    logEvent("purchase_click", product, email, params, "payment");
    // logEventServerSide('purchase_click', email, product, params, 'payment', false, false)
    postHog?.capture("purchase_click", params);

    if (window.location.pathname === "/welcome") {
      // TODO: handle via logEventServerSide
      logEvent("purchase_click", product, email, params, "payment");
      postHog?.capture("purchase_click_welcome", params);
    }
  };

  const onAnnualPress = () => {
    setPlanType(PlanType.Yearly);
    const params: EventParams = {
      amount: `${showPremiumPrices ? ANNUAL_PRICE_PREMIUM : ANNUAL_PRICE}`,
      payment_type: "annual",
    };

    if (featureFlag !== undefined) {
      params["show_sale"] = featureFlag;
    }
    logEvent("purchase_click", product, email, params, "payment");
    // logEventServerSide('purchase_click', email, product, params, 'payment', false, false)
    postHog?.capture("purchase_click", params);

    if (window.location.pathname === "/welcome") {
      // TODO: handle via logEventServerSide
      logEvent("purchase_click", product, email, params, "payment");
      // logEventServerSide('purchase_click', email, product, params, 'payment', false, false)
      postHog?.capture("purchase_click_welcome", params);
    }
  };

  const logPurchaseEvent = async () => {
    console.log("ANALYTICS:: logPurchaseEvent");
    const amount: number =
      planType === PlanType.Monthly
        ? showPremiumPrices
          ? MONTHLY_PRICE_PREMIUM
          : MONTHLY_PRICE
        : showPremiumPrices
        ? ANNUAL_PRICE_PREMIUM
        : ANNUAL_PRICE;
    const value: number =
      planType === PlanType.Monthly
        ? amount * CONVERSION_FACTOR_MONTHLY
        : amount * CONVERSION_FACTOR_ANNUAL;
    const currency = "USD";
    const params: EventParams = {
      amount,
      payment_type: planType === PlanType.Monthly ? "monthly" : "annual",
      value,
      currency,
    };
    if (featureFlag !== undefined) {
      params["show_sale"] = featureFlag;
    }
    const currentProduct = getProductTypeFromPath(window.location.pathname);
    logEvent("purchase_activate", currentProduct, email, params, "payment");
    logEvent(
      "purchase_activate_subscribe",
      currentProduct,
      email,
      params,
      "payment"
    );

    postHog?.capture("purchase_activate", params);
    postHog?.capture("purchase_activate_subscribe", params);

    if (planType === PlanType.Monthly)
      postHog?.capture("purchase_activate_monthly", params);
    else postHog?.capture("purchase_activate_annual", params);
    updateMailcoachSubscriber(["premium"]);
  };

  const onBackPress = () => setPlanType(undefined);

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalProduct, setModalProduct] = useState<ProductType | undefined>(
    undefined
  );

  const openFeaturesModal = (productKey: ProductType) => {
    setModalProduct(productKey);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setModalProduct(undefined);
  };

  return paymentSuccess ? (
    <div className="text-center mt-8">
      <Success title="You have successfully subscribed to Premium." />
    </div>
  ) : isLoading ? (
    <Loading />
  ) : (
    <>
      <div className="relative w-screen left-1/2 right-1/2 -mx-[50vw] flex justify-center">
        <Helmet>
          <meta
            name="description"
            content="Unlimited access to YourMove AI suite of tools for online dating"
          />
        </Helmet>
        <div className="w-full overflow-y-auto flex flex-col h-full">
          {planType && onBackPress && (
            <div className="flex-shrink-0">
              <YourMoveHeader onClose={onBackPress} />
            </div>
          )}

          <div className="overflow-y-auto flex-grow flex flex-col">
            {isSubscribed ? (
              <div className="text-center mt-8 flex-grow">
                <div className="flex items-center justify-center mb-4">
                  <CheckCircleIcon className="text-white h-16 w-16 stroke" />
                </div>
                <h3>You are already subscribed to YourMove premium.</h3>
              </div>
            ) : planType ? (
              <div className="flex-grow flex flex-col py-2 pb-10 px-2">
                <SubscriptionForm
                  planType={planType}
                  email={emailRef.current}
                  product={product}
                  redirectHandler={async () => {
                    let iterations = 0;
                    let isSubscribed = false;
                    while (isSubscribed === false && iterations < 10) {
                      const isSubscribedResponse = await checkUserSubscription(
                        emailRef.current
                      );
                      if (isSubscribedResponse.data.isSubscribed) {
                        isSubscribed = true;
                        await logPurchaseEvent();
                        setTimeout(() => {
                          setIsSubscribed(true);
                          setPaymentSuccess(true);
                        }, 500);
                        const currentProduct = getProductTypeFromPath(
                          window.location.pathname
                        );
                        addToMailingList(emailRef.current, currentProduct);
                        break;
                      } else {
                        await sleep(1000);
                        iterations++;
                      }
                    }

                    if (!isSubscribed) {
                      toast.error(
                        "You Subscribed but there was an error getting your subscription. Automatically refreshing page"
                      );
                      logPurchaseEvent();
                      setTimeout(() => {
                        onDismiss?.();
                      }, 3000);
                    }
                  }}
                />
              </div>
            ) : (
              <div className="flex-grow ">
                {/* {bulletStylePricing === "test" ? ( */}
                <PremiumFeatures
                  openFeaturesModal={(productKey) =>
                    openFeaturesModal(productKey as ProductType)
                  }
                  productType={product as ProductType}
                  onClose={onDismiss}
                />
              </div>
            )}
          </div>
        </div>
      </div>
      {!planType && !isSubscribed && (
        <>
          <div className="bg-brand-primary flex justify-center">
            <div className="max-w-xl w-full overflow-hidden flex flex-col ">
              <PremiumProducts
                onMonthlyPress={onMonthlyPress}
                onAnnualPress={onAnnualPress}
              />
              {showNoThanks && (
                <h3
                  className="text-center cursor-pointer text-lg text-white hover:text-zinc-600 hover:underline mt-2 mb-6"
                  onClick={() => {
                    logEvent(
                      "no_thanks",
                      "profile_writer",
                      email,
                      {},
                      "payment"
                    );
                    window.scrollTo(0, 0);
                    setProfileWriterWizardComplete(true);
                  }}
                >
                  No, thanks
                </h3>
              )}
            </div>
          </div>
          <FamewallReviews
            containerStyle={showNoThanks ? "-px-2" : "px-2"}
            title="Testimonials"
          />
        </>
      )}
      <FeaturesModal
        initialKey={modalProduct}
        open={isModalOpen}
        setOpen={setIsModalOpen}
        onClose={closeModal}
        handleGetStarted={() => {
          setIsModalOpen(false);
          setTimeout(() => {
            if (selectedPlan === "annual") {
              onAnnualPress();
            } else {
              onMonthlyPress();
            }
          }, 250);
        }}
      >
        <PremiumProductsCarousel
          initialKey={modalProduct}
          transparentBackground
        />
      </FeaturesModal>
    </>
  );
};
