import { useEffect } from "react";
import { WizardStep, WizardStepType } from "./models/wizard";
import { useAuthStore } from "./stores/auth";
import toast from "react-hot-toast";
import { ProductType } from "./models/payment";

export const getStepIndex = (
  step: WizardStepType,
  steps: Array<WizardStep>
) => {
  const currentIndex = steps.findIndex(
    (step_: WizardStep) => step_.step == step
  );
  if (currentIndex === -1 || currentIndex === steps.length - 1) {
    return steps.length - 1;
  }
  return currentIndex;
};

export const getStep = (
  step: WizardStepType,
  stepIndex: number,
  steps: Array<WizardStep>
) => {
  return steps[getStepIndex(step, steps) + stepIndex];
};

export function useOutsideAlerter(ref: any, callback: () => void) {
  useEffect(() => {
    /**
     * Alert if clicked on outside of element
     */
    function handleClickOutside(event: any) {
      if (ref.current && !ref.current.contains(event.target)) {
        callback();
      }
    }
    // Bind the event listener
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [ref]);
}

export const removeEmoji = (text: string): string => {
  // Regular expression to match Unicode emojis
  const emojiRegex = /\p{Emoji_Presentation}/gu;

  // Remove emojis from the start of the string
  return text.replace(emojiRegex, "").trim().toLowerCase();
};

export const successfulSignIn = (email: string) => {
  toast.success(`Hey there ${email} 👋, welcome!`, {
    style: { textDecoration: 'none' }
  });
  useAuthStore.getState().setAuthModalIsOpen(false);
  useAuthStore.getState().setAuthModalDismissable(true);
  useAuthStore.getState().setShowAuthSubscriptionDisclaimer(false);
};

export const successfulSignUp = () => {
  toast.success("Successfully signed up, welcome!");
  useAuthStore.getState().setAuthModalIsOpen(false);
  useAuthStore.getState().setAuthModalDismissable(true);
  useAuthStore.getState().setShowAuthSubscriptionDisclaimer(false);
};

export const sleep = (time: number) => {
  return new Promise((resolve) => setTimeout(resolve, time));
};

export const getEndpoint = (url: string): string => {
  const lastSlashIndex = url.lastIndexOf('/');

  // If the URL ends with a slash, return an empty string
  if (lastSlashIndex === url.length - 1) return '';
  // If there's no slash or the last slash is part of the protocol (e.g., http://), return an empty string
  if (lastSlashIndex === -1 || url.indexOf('/', url.indexOf('//') + 2) === -1) return '';
  return url.substring(lastSlashIndex + 1);
}

export const getProductTypeFromPath = (pathname: string): ProductType => {
  switch (pathname) {
    case '/profile-writer':
      return "profile_writer";
    case '/profile-review':
      return "profile_review";
    case '/ai-photo-review':
      return "photo_review";
    case '/ai-photo':
      return "photos";
    default:
      return "chat_assistant";
  }
};

const disposableDomains = [
  'tempmail',
  'throwaway',
  'temporary',
  'fake',
  'temp-mail',
  'tempmail',
  '10minutemail',
  'mailinator',
  'yopmail',
  'guerrillamail'
];

export const isValidEmail = (email: string) => {
  if (!email) return false;
  
  // Basic format checks
  if (!email.includes('@') || !email.includes('.')) return false;
  if (email.length < 5 || email.length > 254) return false; // RFC 5321
  if (email.includes(' ')) return false;
  
  // Check for multiple @ symbols
  if ((email.match(/@/g) || []).length !== 1) return false;
  
  // Split email into local and domain parts
  const [local, domain] = email.split('@');
  
  // Local part checks
  if (!local || local.length > 64) return false; // RFC 5321
  if (local.startsWith('.') || local.endsWith('.')) return false;
  if (local.includes('..')) return false;
  
  // Domain part checks
  if (!domain || domain.length > 255) return false;
  if (domain.startsWith('.') || domain.endsWith('.')) return false;
  if (domain.includes('..')) return false;
  
  // Check for common disposable email domains
  if (disposableDomains.some(d => domain.toLowerCase().includes(d))) return false;
  
  // Check for obviously fake TLDs
  const validTLD = /\.(com|net|org|edu|gov|mil|biz|info|name|museum|[a-z]{2})$/i;
  if (!validTLD.test(domain)) return false;
  
  // Check for valid characters
  const validEmailRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/;
  if (!validEmailRegex.test(email)) return false;

  return true;
};