export const MONTHLY_PRICE = 14;
export const ANNUAL_MONTHLY_PRICE = 5;
export const ANNUAL_PRICE = 60;

export const MONTHLY_PRICE_PREMIUM = 15;
export const ANNUAL_MONTHLY_PRICE_PREMIUM = 6;
export const ANNUAL_PRICE_PREMIUM = 72;

export const SALE_MONTHLY_PRICE = 23;
export const SALE_ANNUAL_MONTHLY_PRICE = 8;
export const SALE_ANNUAL_PRICE = 100;

export const AIPHOTOS_SUBSCRIBED = 15;
// export const AIPHOTOS_SUBSCRIBED_DECIMAL = 14.99
export const AIPHOTOS_UNSUBSCRIBED = 29;
// export const AIPHOTOS_UNSUBSCRIBED_DECIMAL = 28.99

export const SALE_PERCENTAGE = 40;

export const AIPHOTOS_BASIC_PRICE = 29;
export const AIPHOTOS_BASIC_PRICE_SUBSCRIBED = 22;
export const AIPHOTOS_PLUS_PRICE = 49;
export const AIPHOTOS_PLUS_PRICE_SUBSCRIBED = 37;
export const AIPHOTOS_PREMIUM_PRICE = 79;
export const AIPHOTOS_PREMIUM_PRICE_SUBSCRIBED = 59;

export const LOCKED_AIPHOTOS_PRICE = 20;

export const CONVERSION_FACTOR_MONTHLY = 4.5;
export const CONVERSION_FACTOR_ANNUAL = 1;
export const CONVERSION_FACTOR_AIPHOTOS = 1;
