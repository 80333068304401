import { useEffect, useState } from 'react';
import { useWizardStore } from "../stores/wizard";
import { Wizard } from "../components/wizard/Wizard";
import { PROFILE_WRITER_WIZARD_STEPS } from "../constants/wizard";
import { Profile } from "../components/profile/Profile";
import { Helmet } from 'react-helmet-async';
import { checkUserSubscription, hasUserPaid, addToMailingList, submitFeedback, logWriterAppRequest } from '../queries';
import { ProductType } from '../constants/payments';
import { useProfileStore } from '../stores/profile';
import { useAuthStore } from '../stores/auth';
import { useLogEvent } from '../analytics';
import { WizardStepType } from '../models/wizard';
import { Modal } from "../components/modals/Modal";
import toast from "react-hot-toast";

export const ProfileWriter = () => {
  const {
    profileWriterWizardComplete,
    setProfileWriterWizardComplete,
    profileWriterStep,
    setProfileWriterStep,
    profileWriterStepResults,
    email,
    setProfileWriterStepResult,
  } = useWizardStore();

  const [otherAppModalOpen, setOtherAppModalOpen] = useState(false);
  const [confirmationModalOpen, setConfirmationModalOpen] = useState(false);
  const [otherAppInput, setOtherAppInput] = useState('');

  useLogEvent('start', 'profile_writer')

  const { hasPurchasedProfileWriter, setHasPurchasedProfileWriter } = useProfileStore()
  const { isSubscribed, setIsSubscribed } = useAuthStore()

  const checkProfileWriterPurchaseStatus = async () => {
    if (email) {
      console.log('ProfileWriter:: checkProfileWriterPurchaseStatus::hasUserPaid')
      const productResponse = await hasUserPaid(email, [ProductType.ProfileWriter])
      if (productResponse.data.purchasedProducts.includes(ProductType.ProfileWriter)) {
        setHasPurchasedProfileWriter(true)
      }

      console.log('ProfileWriter:: checkProfileWriterPurchaseStatus::checkUserSubscription')
      // To check user subscription again after arrive at the writing style step
      const subscriptionResponse = await checkUserSubscription(email)
      addToMailingList(email, 'profile_writer');
      setIsSubscribed(subscriptionResponse.data.isSubscribed)
    }
  }

  useEffect(() => {
    if (isSubscribed && profileWriterStep === WizardStepType.PAYWALL) {
      setProfileWriterWizardComplete(true);
    }
  }, [isSubscribed])

  // First time the user loads the page, check if they have purchased the profile writer
  useEffect(() => {
    if (profileWriterStep === "writingStyle") return
    checkProfileWriterPurchaseStatus()
  }, [])

  // If the user advances to the writing style step, check if they have purchased the profile writer
  useEffect(() => {
    if (profileWriterStep !== "writingStyle") return
    checkProfileWriterPurchaseStatus()
  }, [profileWriterStep])

  // Modify the effect to only reset the selection when showing modal
  useEffect(() => {
    if (profileWriterStepResults[WizardStepType.PROFILE_TYPE] === "Other") {
      setOtherAppModalOpen(true);
      // Reset the selection so user can choose again after modal closes
      setProfileWriterStepResult(WizardStepType.PROFILE_TYPE, "");
    }
  }, [profileWriterStepResults[WizardStepType.PROFILE_TYPE]]);

  const handleOtherAppSubmit = () => {
    // First log the app request
    logWriterAppRequest(otherAppInput)
      .then(() => {
        // Close first modal and show confirmation modal
        setOtherAppModalOpen(false);
        setConfirmationModalOpen(true);
      })
      .catch(() => {
        // Fail silently as per API design, still show success UI
        setOtherAppModalOpen(false);
        setConfirmationModalOpen(true);
      });
  };

  const handleConfirmationClose = () => {
    // Close confirmation modal and reset everything
    setConfirmationModalOpen(false);
    setOtherAppInput('');
    setProfileWriterStepResult(WizardStepType.PROFILE_TYPE, "");
    // Set to Hinge in the next tick
    setTimeout(() => {
      setProfileWriterStepResult(WizardStepType.PROFILE_TYPE, "Hinge");
    }, 0);
  };

  return (
    <>
      <Helmet>
        <meta name="title" content="Dating Profile Generator" />
        <meta name="description" content="Instantly generate an optimized bio for your dating profile, optimized to show your best side and attract more matches." />
      </Helmet>
      {/* Other app modal - if the user selects "Other" when choosing an app */}
      <Modal open={otherAppModalOpen} setOpen={setOtherAppModalOpen}>
        <div className="p-4">
          <h2 className="text-xl font-semibold mb-6 -mt-6">What app would you like to write a profile for?</h2>
          <div className="mb-6">
            <input
              type="text"
              value={otherAppInput}
              onChange={(e) => setOtherAppInput(e.target.value)}
              placeholder="Enter app name"
              className="w-full p-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-[#410B13] focus:border-transparent"
            />
          </div>
          <div className="flex flex-col gap-3">
            <button
              onClick={handleOtherAppSubmit}
              className="w-full bg-[#410B13] text-white px-4 py-2 rounded-lg hover:bg-opacity-90 transition-colors"
            >
              Submit
            </button>
          </div>
        </div>
      </Modal>
      {/* Confirmation modal - shows after submitting app request */}
      <Modal open={confirmationModalOpen} setOpen={setConfirmationModalOpen}>
        <div className="p-4">
          <h2 className="text-xl font-semibold mb-6 -mt-6">Thanks for your suggestion!</h2>
          <p className="mb-6 text-gray-700">
          We're always adding support for new apps and will let you know when yours becomes available. In the meantime, we recommend using Hinge—it works well for most profiles. 
          </p>
          <p className="mb-6 text-gray-700">
         
          </p>
          <div className="flex flex-col gap-3">
            <button
              onClick={handleConfirmationClose}
              className="w-full bg-[#410B13] text-white px-4 py-2 rounded-lg hover:bg-opacity-90 transition-colors"
            >
              Sounds good
            </button>
          </div>
        </div>
      </Modal>
      <Wizard
        name="profileWriter"
        steps={PROFILE_WRITER_WIZARD_STEPS.filter(step => 
          !(step.step === WizardStepType.PAYWALL && (hasPurchasedProfileWriter || !!isSubscribed))
        )}
        wizardComplete={profileWriterWizardComplete}
        setWizardComplete={setProfileWriterWizardComplete}
        step={profileWriterStep}
        setStep={setProfileWriterStep}
        stepResults={profileWriterStepResults}
        setStepResult={setProfileWriterStepResult}
        storeStep={true}
        hasPaid={hasPurchasedProfileWriter || !!isSubscribed}
      >
        {profileWriterWizardComplete && <Profile />}
      </Wizard>
    </>
  );
};
