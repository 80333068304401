import { useCallback, useState } from "react";
import { FileRejection, useDropzone } from "react-dropzone";
import { useAIPhotosStore } from "../../stores/aiphotos";
import { toast } from "react-hot-toast";

const MAX_FILES = 30;

export const useAIPhotosUpload = (
  onFilesUploaded?: VoidFunction,
  overwrite = false
) => {
  const { setFilesToUpload, filesToUpload } = useAIPhotosStore();
  const [uploading, setUploading] = useState(false);

  const onDrop = useCallback(
    async (acceptedFiles: File[], rejectedFiles: FileRejection[]) => {
      try {
        if (
          rejectedFiles.length > 0 &&
          rejectedFiles.some((rejection) =>
            rejection.errors.some((error) => error.code === "too-many-files")
          )
        ) {
          toast.error(
            `Maximum file limit exceeded. Please upload maximum ${MAX_FILES} photos.`
          );
          return;
        }
        const updatedFiles = overwrite
          ? acceptedFiles
          : [...filesToUpload, ...acceptedFiles];
        if (updatedFiles.length > MAX_FILES) {
          toast.error(
            `Maximum file limit exceeded. Please upload maximum ${MAX_FILES} photos.`
          );
          return;
        } else if (updatedFiles.length === 0) {
          toast.error("Please upload at least one photo.");
          return;
        }
        if (updatedFiles.length > 0) {
          setUploading(true);
          setFilesToUpload(updatedFiles);
          onFilesUploaded?.();
        }
      } catch (error) {
        console.error("Error uploading files:", error);
      } finally {
        setUploading(false);
      }
    },
    [setFilesToUpload, onFilesUploaded]
  );

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: {
      "image/jpeg": [".jpg", ".jpeg"],
      "image/png": [".png"],
    },
    maxFiles: MAX_FILES,
    disabled: uploading,
  });

  return { getRootProps, getInputProps, uploading };
};
