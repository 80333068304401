import { useAIPhotosStore } from "../../stores/aiphotos";
import { createAIPhotosRequest, createTune, generatePrompts } from "../../queries";
import { useSupabaseImageUpload } from "./useSupabaseImageUpload";
import { useEffect, useState } from "react";
import { useRef } from "react";
import { AIPhotosPlan } from "../../constants/payments";

export enum AIPhotosProcessingStatus {
  NONE = 'none',
  UPLOADING = 'uploading',
  CREATING_REQUEST = 'creating_request',
  PROCESSING = 'processing',
  TRAINING = 'training',
  COMPLETE = 'complete',
}

const getBatchSize = (plan: AIPhotosPlan) => {
  console.log('AIPhotosPlan received:', plan);
  switch (plan) {
    case AIPhotosPlan.Premium:
      return 50;
    case AIPhotosPlan.Plus:
      return 50;
    case AIPhotosPlan.Basic:
      return 31;
    default:
      return 10;
  }
};

const getLockedPhotos = (plan: AIPhotosPlan) => {
  console.log('AIPhotosPlan received:', plan);
  switch (plan) {
    case AIPhotosPlan.Plus:
      return 100;
    case AIPhotosPlan.Basic:
      return 100;
    default:
      return 0;
  }
};

export const useAIPhotosTask = (selectedPlan: AIPhotosPlan) => {
  console.log('useAIPhotosTask initialized with plan:', selectedPlan);

  const { aiPhotosEmail } = useAIPhotosStore()
  const { gender } = useAIPhotosStore()
  const { uploadImagesToSupabase } = useSupabaseImageUpload();

  const [aiPhotosProcessingStatus, setAiPhotosProcessingStatus] = useState<AIPhotosProcessingStatus>(AIPhotosProcessingStatus.NONE);

  const { filesToUpload } = useAIPhotosStore()

  const userEmail = useRef(aiPhotosEmail)

  useEffect(() => {
    userEmail.current = aiPhotosEmail
  }, [aiPhotosEmail])

  const trainTune = async (title: string, imageUrls: string[]) => {
    try {
      const response = await createTune(userEmail.current, title, imageUrls, gender, "SKS", "898062");
      console.log('TUNE RESPONSE::', response);
      const tuneId = response.data.tune_id;
      console.log("Tune successfully created:", tuneId);
      return {
        title,
        tuneId,
      };
    } catch (err) {
      console.error("Error creating tune:", JSON.stringify(err));
      throw new Error("Failed to create tune. Please try again.");
    }
  };

  const trainPrompts = async (tuneId: string): Promise<void> => {
    const response = await generatePrompts(
      userEmail.current, 
      gender, 
      tuneId, 
      getBatchSize(selectedPlan)
    );
    console.log('GENERATE PROMPTS RESPONSE::', response);
  };


  const initiateAIPhotosRequest = async (title: string, uploadedUrls: string[]) => {
    try {
      console.log('Initiating AI Photos Request::', Date.now(), title);
      const result = await trainTune(title, uploadedUrls);
      if (!result) {
        throw new Error("Failed to create tune.");
      }
      const { tuneId } = result;
      console.log("Tune ID:", tuneId);
      setAiPhotosProcessingStatus(AIPhotosProcessingStatus.TRAINING);
      await trainPrompts(tuneId);
      console.log('Initiated AI Photos Request', tuneId);
      return {
        title,
        tuneId,
      };
    } catch (error) {
      console.error("Error in initiateAIPhotosRequest:", error);
      throw error;
    }
  };

  const generateAIPhotos = async () => {
    try {
      const timeStamp = Date.now()
      const title = `${userEmail.current}-${timeStamp}`;

      setAiPhotosProcessingStatus(AIPhotosProcessingStatus.UPLOADING);
      console.log('Uploading Images to Supabase::');
      const uploadedUrls = await uploadImagesToSupabase(filesToUpload, userEmail.current, title);
      console.log('Uploaded Images to Supabase::', uploadedUrls);

      setAiPhotosProcessingStatus(AIPhotosProcessingStatus.CREATING_REQUEST);
      const createResponse = await createAIPhotosRequest(
        userEmail.current, 
        uploadedUrls, 
        title ?? '', 
        getBatchSize(selectedPlan),
        getLockedPhotos(selectedPlan),
      );
      console.log('Create Response::', createResponse);
      console.log('Generating AI Photos::', Date.now());

      setAiPhotosProcessingStatus(AIPhotosProcessingStatus.PROCESSING);
      const result = await initiateAIPhotosRequest(title, uploadedUrls);

      setAiPhotosProcessingStatus(AIPhotosProcessingStatus.COMPLETE);
      console.log('Request initiated for::', result.tuneId);
    } catch (error) {
      console.error("Error in generateAIPhotos:", error);
      throw error;
    }
  };

  return {
    aiPhotosProcessingStatus,
    generateAIPhotos,
  };
}