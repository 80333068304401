import { useEffect, useRef, useState } from "react";
import { AIPhotosBatches } from "./AIPhotosBatches";
import { useAIPhotosResults } from "./useAIPhotosResults";
import { AIPhotosBatchDetail } from "./AIPhotosBatchDetail";
import { AIPhotosWizardStep, useAIPhotosStore } from "../../stores/aiphotos";
import { getScrollableParent } from './utils';
import { LockedAIPhotosModal } from "../../components/modals/LockedAIPhotosModal";
import { updateLockedAIPhotos } from "../../queries";
import toast from "react-hot-toast";

type Props = {
  experimentVariant: 'control' | 'treatment';
}

export const AIPhotosResults = ({ experimentVariant }: Props) => {
  const [openLockedAIPhotosModal, setOpenLockedAIPhotosModal] = useState(false)
  const [selectedBatch, setSelectedBatch] = useState<number | undefined>(undefined);
  const { setAIPhotosWizardComplete, setAIPhotosWizardStep } = useAIPhotosStore()

  const { loadingBatches, fetchAIPhotoBatches, batches } = useAIPhotosResults()

  useEffect(() => {
    fetchAIPhotoBatches()
  }, [])

  const containerRef = useRef<HTMLDivElement>(null);

  const onBatchClick = (id: number) => {
    console.log("selected batch:", batches[id]);
    setSelectedBatch(id);
    if (containerRef.current) {
      const scrollableParent = getScrollableParent(containerRef.current);
      if (scrollableParent) {
        scrollableParent.scrollTo({
          top: 0,
          behavior: 'instant'
        });
      }
    }
  };
  const handleBackClick = () => {
    setSelectedBatch(undefined);
  };

  const handleGenerateNewBatch = () => {
    if (experimentVariant === 'treatment') {
      setAIPhotosWizardStep(AIPhotosWizardStep.EMAIL_INPUT)
    }
    else {
      setAIPhotosWizardStep(AIPhotosWizardStep.UPLOAD_IMAGES)
    }

    setAIPhotosWizardComplete(false)
  };

  const onPaymentSuccessful = async () => {
    try {
      setOpenLockedAIPhotosModal(false);
      const { email, title } = batches[selectedBatch!];
      await updateLockedAIPhotos(email, title);
      toast.success("Successfully unlocked more photos!");
      setSelectedBatch(undefined);
      fetchAIPhotoBatches();
    }
    catch (e) {
      console.log(e);
      toast.error("Failed to unlock more photos");
    }
  }

  const handleUnlockClick = () => {
    setOpenLockedAIPhotosModal(true);
  }

  return (
    <>
      <div ref={containerRef} className="flex flex-col h-full max-w-lg mx-auto px-4 md:px-0">
        {selectedBatch !== undefined ? (
          <AIPhotosBatchDetail
            imageUrls={batches[selectedBatch]?.outputUrls || []}
            lockedPhotos={batches[selectedBatch]?.lockedPhotos || 0}
            onBackClick={handleBackClick}
            onUnlockClick={handleUnlockClick}
          />
        ) : (
          <>
            <div className="w-full my-4">
              <button
                onClick={handleGenerateNewBatch}
                className="flex items-center justify-center w-full bg-brand-primary text-white py-3 rounded-md font-semibold"
              >
                Request another set
              </button>
            </div>
            <AIPhotosBatches
              loading={loadingBatches}
              batches={batches}
              onBatchClick={onBatchClick}
            />
          </>
        )}
      </div>

      <LockedAIPhotosModal open={openLockedAIPhotosModal} setOpen={setOpenLockedAIPhotosModal} redirectHandler={onPaymentSuccessful} />
    </>
  );
};