import { useEffect, useState } from "react";
import { Product } from "../components/onboarding/Product";
import ProfileReview from "../assets/images/onboarding/profile-review.webp";
import ProfileWriter from "../assets/images/onboarding/profile-writer.webp";
import ChatAssistant from "../assets/images/onboarding/chat-assistant.webp";
import { useNavigate } from "react-router-dom";
import { Helmet } from 'react-helmet-async';
import posthog from 'posthog-js';
import OnboardPhoto from "../assets/images/onboarding/v2/onboard-photo.webp";
import OnboardWriter from "../assets/images/onboarding/v2/onboard-writer.webp";
import OnboardReview from "../assets/images/onboarding/v2/onboard-review.webp";
import OnboardTexting from "../assets/images/onboarding/v2/onboard-texting.webp";


const OPTIONS = [
  {
    emoji: "💞",
    title: "Get more\nmatches",
    recommended: "/profile-review",
  },
  {
    emoji: "🍷",
    title: "Get more\ndates",
    recommended: "/chat-assistant",
  },
];

const OPTIONS_V2 = [
  {
    emoji: "💝",
    title: "Get more matches",
    recommended: "/profile-review",
  },
  {
    emoji: "🎯",
    title: "Find higher-quality matches",
    recommended: "/profile-review",
  },
  {
    emoji: "🍷",
    title: "Turn more matches into dates",
    recommended: "/chat-assistant",
  },
];

const PRODUCT_CARDS = [
  {
    title: "AI Photoshoot",
    image: OnboardPhoto,
    url: "/ai-photo",
    recommended: true
  },
  {
    title: "Profile Writer",
    image: OnboardWriter,
    url: "/profile-writer"
  },
  {
    title: "Profile Review",
    image: OnboardReview,
    url: "/profile-review"
  },
  {
    title: "Texting Assistant",
    image: OnboardTexting,
    url: "/chat-assistant"
  }
];

export const Onboarding = () => {
  const navigate = useNavigate();
  const [selectedOption, setSelectedOption] = useState<number | undefined>(undefined);
  const [isOptionSelected, setIsOptionSelected] = useState(false);

  // Once we show the onboarding once, we don't want to show it again,
  // so we set a flag in local storage to indicate that the user has onboarded
  useEffect(() => {
    localStorage.setItem("hasOnboarded", "true");
    // posthog.featureFlags.override({'gen-onboarding-v2': 'test'});
  }, []);

  return (
    <div className="max-w-sm mx-auto mt-0">
      <Helmet>
        <meta name="title" content="YourMove - Welcome" />
        <meta name="description" content="Get more matches or turn more matches into dates" />
      </Helmet>
      <div>
        {/* <div className="flex items-center justify-center">
          <Logo />
        </div> */}

        {posthog.getFeatureFlag('gen-onboarding-v2') === 'test' ? (
          <div className="px-4 mt-2">
            <div className={`transition-opacity duration-500 ease-in-out ${isOptionSelected ? 'opacity-0' : 'opacity-100'}`}>
              {!isOptionSelected && (
                <div>
                  <h1 className="text-2xl font-semibold mb-4">What's your next move?</h1>
                  <div className="space-y-3">
                    {OPTIONS_V2.map((option, index) => (
                      <div
                        key={index}
                        onClick={() => {
                          setSelectedOption(index);
                          setIsOptionSelected(true);
                          posthog.capture('Onboarding Option Click', {
                            test_group: 'treatment',
                            option: option.title
                          });
                        }}
                        className={`flex items-center p-4 rounded-full border-2 cursor-pointer transition-all
                          ${selectedOption === index 
                            ? "border-brand-primary bg-white" 
                            : "border-black bg-white hover:border-brand-primary"
                          }`}
                      >
                        <span className="text-xl mr-3">{option.emoji}</span>
                        <span className="text-lg font-medium">{option.title}</span>
                      </div>
                    ))}
                  </div>
                </div>
              )}
            </div>

            <div className={`transition-opacity duration-500 ease-in-out ${isOptionSelected ? 'opacity-100' : 'opacity-0'}`}>
              {isOptionSelected && (
                <div>
                  <h1 className="text-2xl font-semibold mb-4">How can we help?</h1>
                  <div className="grid grid-cols-2 gap-4">
                    {PRODUCT_CARDS.map((card, index) => (
                      <div 
                        key={index}
                        className="relative rounded-lg overflow-hidden border-2 border-black text-white cursor-pointer"
                        onClick={() => {
                          console.log(`Logging PostHog event - Product Button Clicked: ${card.title} (treatment)`);
                          posthog.capture('Product Button Clicked', {
                            test_group: 'treatment',
                            product: card.title,
                          });
                          navigate(card.url);
                        }}
                      >
                        <img 
                          src={card.image} 
                          alt={card.title}
                          className="w-full h-full object-cover"
                        />
                        <div className="absolute inset-x-0 bottom-0 h-[45%]">
                          <div className="absolute inset-x-0 bottom-0 h-1/2 bg-black bg-opacity-90"></div>
                          <div className="absolute inset-x-0 bottom-1/2 h-1/2 bg-gradient-to-t from-black/90 to-transparent"></div>
                        </div>
                        <div className="absolute inset-0 p-3 flex flex-col justify-end">
                          {card.recommended && (
                            <div className="text-red-500 text-sm font-medium mb-0.5 flex items-center">
                              <svg 
                                xmlns="http://www.w3.org/2000/svg" 
                                viewBox="0 0 24 24" 
                                fill="currentColor" 
                                className="w-4 h-4 mr-1"
                              >
                                <path 
                                  fillRule="evenodd" 
                                  d="M9 4.5a.75.75 0 0 1 .721.544l.813 2.846a3.75 3.75 0 0 0 2.576 2.576l2.846.813a.75.75 0 0 1 0 1.442l-2.846.813a3.75 3.75 0 0 0-2.576 2.576l-.813 2.846a.75.75 0 0 1-1.442 0l-.813-2.846a3.75 3.75 0 0 0-2.576-2.576l-2.846-.813a.75.75 0 0 1 0-1.442l2.846-.813A3.75 3.75 0 0 0 7.466 7.89l.813-2.846A.75.75 0 0 1 9 4.5ZM18 1.5a.75.75 0 0 1 .728.568l.258 1.036c.236.94.97 1.674 1.91 1.91l1.036.258a.75.75 0 0 1 0 1.456l-1.036.258c-.94.236-1.674.97-1.91 1.91l-.258 1.036a.75.75 0 0 1-1.456 0l-.258-1.036a2.625 2.625 0 0 0-1.91-1.91l-1.036-.258a.75.75 0 0 1 0-1.456l1.036-.258a2.625 2.625 0 0 0 1.91-1.91l.258-1.036A.75.75 0 0 1 18 1.5ZM16.5 15a.75.75 0 0 1 .712.513l.394 1.183c.15.447.5.799.948.948l1.183.395a.75.75 0 0 1 0 1.422l-1.183.395c-.447.15-.799.5-.948.948l-.395 1.183a.75.75 0 0 1-1.422 0l-.395-1.183a1.5 1.5 0 0 0-.948-.948l-1.183-.395a.75.75 0 0 1 0-1.422l1.183-.395c.447-.15.799-.5.948-.948l.395-1.183A.75.75 0 0 1 16.5 15Z" 
                                  clipRule="evenodd" 
                                />
                              </svg>
                              <span>Recommended</span>
                            </div>
                          )}
                          <div className="text-white font-medium flex justify-between items-center">
                            <div>{card.title}</div>
                            <div>→</div>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              )}
            </div>
          </div>
        ) : (
          // Control variant
          <>
            <div className="px-4 -mt-4">
              <h1 className="text-2xl font-semibold">What's your next move?</h1>
            </div>
            <div className="ml-4 mt-3 overflow-x-scroll flex no-scrollbar">
              {OPTIONS.map((option, index) => {
                return (
                  <div
                    className={`bg-white border-2 mr-4 p-3 w-1/2 rounded-2xl cursor-pointer whitespace-pre-line ${selectedOption === index
                      ? "border-brand-primary"
                      : "border-black"
                      }`}
                    onClick={() => setSelectedOption(index)}
                  >
                    <div className="flex items-center">
                      <h1 className="text-xl">{option.emoji}</h1>
                      <h2
                        className={`ml-2 text-lg font-semibold leading-tight ${selectedOption === index
                          ? "text-brand-primary"
                          : "text-black"
                          }`}
                      >
                        {option.title}
                      </h2>
                    </div>
                  </div>
                );
              })}
            </div>
            {selectedOption !== undefined && (
              <>
                <div
                  className="overflow-y-scroll p-4"
                  style={{
                    height: window.innerHeight - 225,
                  }}
                >
                  {selectedOption === 0 && (
                    <>
                      <Product
                        isRecommended={true}
                        title="Try Profile Writer"
                        description="Tell us about yourself. Get an instant bio. Tested and optimized for conversion. Works with Hinge, Tinder, Bumble, and more."
                        image={ProfileWriter}
                        url="/profile-writer"
                        onClick={() => {
                          // console.log('Logging PostHog event - Product Button Clicked: Profile Writer (control)');
                          posthog.capture('Product Button Clicked', {
                            test_group: 'control',
                            product: 'Profile Writer',
                          });
                        }}
                      />
                      <Product
                        isRecommended={false}
                        title="Try Profile Reviewer"
                        description="Detailed review of your profile photos + bio with actionable tips to improve your matches."
                        image={ProfileReview}
                        url="/profile-review"
                        onClick={() => {
                          // console.log('Logging PostHog event - Product Button Clicked: Profile Reviewer (control)');
                          posthog.capture('Product Button Clicked', {
                            test_group: 'control',
                            product: 'Profile Reviewer',
                          });
                        }}
                      />

                    </>
                  )}
                  {selectedOption === 1 && (
                    <>
                      <Product
                        isRecommended={true}
                        title="Try Chat Assistant"
                        description="Access winning icebreakers and reply suggestions to ignite connections and turn matches into dates."
                        image={ChatAssistant}
                        url="/chat-assistant"
                        onClick={() => {
                          // console.log('Logging PostHog event - Product Button Clicked: Chat Assistant (control)');
                          posthog.capture('Product Button Clicked', {
                            test_group: 'control',
                            product: 'Chat Assistant',
                          });
                        }}
                      />
                    </>
                  )}
                </div>
                <div className="fixed bottom-0 left-0 w-full h-16 z-10 px-4">
                  <div className="max-w-lg mx-auto">
                    <div
                      className="bg-brand-primary text-center text-white font-semibold p-3 rounded-lg"
                      onClick={() => {
                        // console.log('Logging PostHog event - Product Button Clicked: Chat Assistant (control)');
                        const option = OPTIONS[selectedOption]
                        if (option) {
                          navigate(option.recommended);
                        }
                      }}
                    >
                      <h1 className="text-lg">Get Started</h1>
                    </div>
                  </div>
                </div>
              </>
            )}
          </>
        )}
      </div>
    </div>
  );
};
