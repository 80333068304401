import { XCircleIcon } from "@heroicons/react/24/outline";
import {
  PaymentElement,
  useElements,
  useStripe,
} from "@stripe/react-stripe-js";
import { StripePaymentElementOptions } from "@stripe/stripe-js";
import { useState, useEffect, useRef } from "react";
import { useUIStore } from "../../stores/ui";
import {
  paymentIntentForAIPhotos,
  updateMailcoachSubscriber,
  validatePromoCode,
} from "../../queries";
import { AIPhotosPlan } from "../../constants/payments";
import { useAIPhotosStore } from "../../stores/aiphotos";
import { logEvent, logEventServerSide } from "../../analytics";
import { usePostHog } from "posthog-js/react";
import {
  AIPHOTOS_BASIC_PRICE,
  AIPHOTOS_BASIC_PRICE_SUBSCRIBED,
  AIPHOTOS_PLUS_PRICE,
  AIPHOTOS_PREMIUM_PRICE,
} from "../../pages/premium/paymentsConfig";
import { CONVERSION_FACTOR_AIPHOTOS } from "../../pages/premium/paymentsConfig";
import { AIPHOTOS_PREMIUM_PRICE_SUBSCRIBED } from "../../pages/premium/paymentsConfig";
import { AIPHOTOS_PLUS_PRICE_SUBSCRIBED } from "../../pages/premium/paymentsConfig";
import { useAuthStore } from "../../stores/auth";
import { usePaymentStore } from "../../stores/payment";
import { toast } from "react-hot-toast";

interface Props {
  redirectHandler?: () => void;
  returnUrl: string;
  email?: string;
  selectedPlan: AIPhotosPlan;
  onPromoValidated: (percent_off: number) => void;
}

export default function AIPhotosPaymentForm({
  redirectHandler,
  returnUrl,
  selectedPlan,
  onPromoValidated,
}: Props) {
  const stripe = useStripe();
  const elements = useElements();
  const [message, setMessage] = useState<string | null>(null);
  const { paymentIsLoading, setPaymentIsLoading } = useUIStore();
  const { aiPhotosEmail } = useAIPhotosStore();
  const { isSubscribed } = useAuthStore();
  const postHog = usePostHog();
  const { discountCode, setDiscountCode } = usePaymentStore();
  const [percentageOff, setPercentageOff] = useState<number | undefined>(undefined);
  const promoCode = useRef<string | undefined>(discountCode);
  const promoId = useRef<string | undefined>(undefined);
  const [applyPromoEnabled, setApplyPromoEnabled] = useState(false);

  useEffect(() => {
    if (discountCode) {
      promoCode.current = discountCode;
      applyPromoCode();
    }
  }, [discountCode]);

  const applyPromoCode = async () => {
    if (promoCode.current) {
      setMessage(null);

      const promoResponse = (await validatePromoCode(promoCode.current)) || {};
      const {
        data: {
          verified = false,
          promo: {
            id = undefined,
            active = true,
            coupon: { percent_off = 0, valid = false } = {},
          } = {},
        } = {},
      } = promoResponse || {};

      if (!verified || !active || !valid) {
        toast.error("Promo code invalid or expired. ");
        setPaymentIsLoading(false);
        return;
      } else {
        promoId.current = id;
        setPercentageOff(percent_off);
        onPromoValidated(percent_off);
        setApplyPromoEnabled(false);
        toast.success("Promo code successful");
      }
    }
  };

  const handleSubmit = async (e: any) => {
    e.preventDefault();

    if (!stripe || !elements) {
      // Stripe.js hasn't yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }
    setMessage(null);
    setPaymentIsLoading(true);

    const { error: submitError } = await elements.submit();
    if (submitError) {
      setMessage(
        `${submitError.message ? submitError.message : "An unknown error occurred!"}`
      );
      setPaymentIsLoading(false);
      return;
    }

    const intentResponse = await paymentIntentForAIPhotos(
      aiPhotosEmail,
      selectedPlan,
      promoId.current
    );
    const { data: { clientSecret = undefined } = {} } = intentResponse || {};

    if (!clientSecret) {
      setPaymentIsLoading(false);
      return;
    }

    const { paymentIntent, error } = await stripe.confirmPayment({
      elements,
      clientSecret,
      confirmParams: {
        return_url: returnUrl,
      },
      redirect: "if_required",
    });

    if (
      paymentIntent &&
      paymentIntent.status === "succeeded" &&
      redirectHandler
    ) {
      setPaymentIsLoading(false);
      const amount = isSubscribed
        ? selectedPlan === AIPhotosPlan.Plus
          ? AIPHOTOS_PLUS_PRICE_SUBSCRIBED
          : selectedPlan === AIPhotosPlan.Premium
          ? AIPHOTOS_PREMIUM_PRICE_SUBSCRIBED
          : AIPHOTOS_BASIC_PRICE_SUBSCRIBED
        : selectedPlan === AIPhotosPlan.Plus
        ? AIPHOTOS_PLUS_PRICE
        : selectedPlan === AIPhotosPlan.Premium
        ? AIPHOTOS_PREMIUM_PRICE
        : AIPHOTOS_BASIC_PRICE;
      const value = amount * CONVERSION_FACTOR_AIPHOTOS;
      const currency = "USD";

      // Replace client-side tracking with server-side tracking
      logEvent(
        "purchase_activate",
        "ai_photos",
        aiPhotosEmail,
        { payment_type: selectedPlan, amount, value, currency },
        "payment"
      );
      logEvent(
        "purchase_activate_photos",
        "ai_photos",
        aiPhotosEmail,
        { payment_type: selectedPlan, amount, value, currency },
        "payment"
      );
      logEvent(
        "purchase_batch",
        "ai_photos",
        aiPhotosEmail,
        { payment_type: selectedPlan, amount, value, currency },
        "payment"
      );

      postHog?.capture("purchase_activate", {
        payment_type: selectedPlan,
        amount,
        value,
        currency,
      });
      postHog?.capture("purchase_activate_photos", {
        payment_type: selectedPlan,
        amount,
        value,
        currency,
      });
      console.log("purchase_activate event fired for AI Photos:", {
        payment_type: selectedPlan,
      });
      updateMailcoachSubscriber(["premium"]);

      redirectHandler();
      return;
    }

    // This point will only be reached if there is an immediate error when
    // confirming the payment. Otherwise, your customer will be redirected to
    // your `return_url`. For some payment methods like iDEAL, your customer will
    // be redirected to an intermediate site first to authorize the payment, then
    // redirected to the `return_url`.

    if (error) {
      setMessage(
        `${error.message ? error.message : "An unknown error occurred!"}`
      );
      setPaymentIsLoading(false);
    }
  };

  const paymentElementOptions: StripePaymentElementOptions = {
    layout: "tabs",
  };

  // Add price calculation logic
  const basePrice = isSubscribed
    ? selectedPlan === AIPhotosPlan.Plus
      ? AIPHOTOS_PLUS_PRICE_SUBSCRIBED
      : selectedPlan === AIPhotosPlan.Premium
      ? AIPHOTOS_PREMIUM_PRICE_SUBSCRIBED
      : AIPHOTOS_BASIC_PRICE_SUBSCRIBED
    : selectedPlan === AIPhotosPlan.Plus
    ? AIPHOTOS_PLUS_PRICE
    : selectedPlan === AIPhotosPlan.Premium
    ? AIPHOTOS_PREMIUM_PRICE
    : AIPHOTOS_BASIC_PRICE;

  const discountedPrice = percentageOff
    ? basePrice - basePrice * (percentageOff / 100)
    : basePrice;

  return (
    <div id="payment-form" className="max-h-90">
      {/* <div className="flex justify-between items-center mb-4">
        <div>once off</div>
        <div className="flex flex-col items-end">
          {!!percentageOff && (
            <span className="text-red-500">
              ${discountedPrice.toFixed(2)}
            </span>
          )}
          <div className={`${percentageOff ? "line-through -mt-1" : ""}`}>
            ${basePrice.toFixed(2)}
          </div>
        </div>
      </div> */}

      <PaymentElement id="payment-element" options={paymentElementOptions} />
      <button
        type="button"
        onClick={(e) => handleSubmit(e)}
        id="submit"
        className="mt-4 flex items-center justify-center w-full bg-brand-primary text-white py-3 rounded-full font-semibold -mb-1"
      >
        <span id="button-text">
          {paymentIsLoading ? (
            <div className="flex items-center justify-center">
              Processing
              <svg
                className="animate-spin -ml-1 ml-3 h-5 w-5 text-white"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
              >
                <circle
                  className="opacity-25"
                  cx="12"
                  cy="12"
                  r="10"
                  stroke="currentColor"
                  strokeWidth="4"
                ></circle>
                <path
                  className="opacity-75"
                  fill="currentColor"
                  d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                ></path>
              </svg>
            </div>
          ) : (
            "Pay now"
          )}
        </span>
      </button>
      {/* Show any error or success messages */}
      {message && (
        <div className="rounded-md bg-red-50 p-4 mt-5">
          <div className="flex">
            <div className="flex-shrink-0">
              <XCircleIcon
                className="h-5 w-5 text-red-400"
                aria-hidden="true"
              />
            </div>
            <div className="ml-3">
              <h3 className="text-sm font-medium text-red-800">{message}</h3>
            </div>
          </div>
        </div>
      )}
      {discountCode && (
        <div>
          <label className="block my-2 text-zinc-700">Promo Code</label>
          <div className="flex mb-4">
            <input
              className="p-2 w-full border-solid border-[1px] border-neutral-200 rounded text-gray-500"
              type="text"
              placeholder="optional"
              disabled={!!percentageOff}
              value={discountCode}
              onChange={(e) => {
                promoCode.current = e.target.value;
                setApplyPromoEnabled(!!promoCode.current);
              }}
            />
            <button
              disabled={!applyPromoEnabled}
              type="button"
              onClick={applyPromoCode}
              id="submit"
              className={`bg-brand-primary text-white rounded-lg w-28 font-semibold ml-4 my-1 ${
                !applyPromoEnabled
                  ? "opacity-50 cursor-not-allowed"
                  : "opacity-100"
              }`}
            >
              Apply
            </button>
          </div>
        </div>
      )}
    </div>
  );
}
