import { FaChevronLeft, FaDownload, FaChevronRight, FaUnlock, FaArrowLeft } from "react-icons/fa";

type Props = {
  imageUrl: string;
  imageIndex: number;
  lockedPhotos: number;
  totalImages: number;
  onBackClick: () => void;
  onNextClick?: () => void;
  onPreviousClick?: () => void;
  onUnlockClick?: () => void;
};

export const AIPhotoDetailView = ({
  imageUrl,
  imageIndex,
  lockedPhotos,
  totalImages,
  onBackClick,
  onNextClick,
  onPreviousClick,
  onUnlockClick
}: Props) => {
  const isImageLocked = imageIndex >= totalImages - lockedPhotos;
  const downloadImage = async () => {
    try {
      const response = await fetch(imageUrl);
      const blob = await response.blob();
      const blobUrl = URL.createObjectURL(blob);

      const link = document.createElement("a");
      link.href = blobUrl;
      link.download = `downloaded-image-${imageIndex + 1}.jpg`;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);

      URL.revokeObjectURL(blobUrl);
    } catch (error) {
      console.error("Failed to download the image:", error);
    }
  };

  return (
    <div className="fixed inset-0 flex flex-col justify-between bg-black text-white z-50 overflow-hidden h-full">
      <div className="absolute top-12 left-0 right-0 z-50 flex justify-between w-full p-4">
        <FaArrowLeft
          className="text-xl cursor-pointer"
          onClick={onBackClick}
        />
        <span className="text-sm">
          {imageIndex + 1}/{totalImages}
        </span>
      </div>

      <div className="flex justify-center items-center flex-1 mt-10 mb-10 relative">
        {/* Previous arrow */}
        {imageIndex > 0 && onPreviousClick && (
          <div className="absolute left-4 cursor-pointer p-2 bg-black/50 rounded-full z-10">
            <FaChevronLeft className="text-2xl" onClick={onPreviousClick} />
          </div>
        )}

        <div className="relative">
          <img
            src={imageUrl}
            alt="Displayed Image"
            className={`max-w-full max-h-[90vh] rounded-md ${isImageLocked ? 'blur-[4px]' : ''}`}
            onContextMenu={(e) => {
              if (isImageLocked) e.preventDefault(); // Disable right-click for locked images to prevent download
            }}
          />
          {isImageLocked && (
            <div className="absolute inset-0 flex items-center justify-center bg-black opacity-30">
              <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="100%" height="100%" viewBox="0 0 64 64" fill="white" opacity="0.6">
                <path d="M 32 9 C 24.832 9 19 14.832 19 22 L 19 27.347656 C 16.670659 28.171862 15 30.388126 15 33 L 15 49 C 15 52.314 17.686 55 21 55 L 43 55 C 46.314 55 49 52.314 49 49 L 49 33 C 49 30.388126 47.329341 28.171862 45 27.347656 L 45 22 C 45 14.832 39.168 9 32 9 z M 32 13 C 36.963 13 41 17.038 41 22 L 41 27 L 23 27 L 23 22 C 23 17.038 27.037 13 32 13 z M 21 31 L 43 31 C 44.105 31 45 31.895 45 33 L 45 49 C 45 50.105 44.105 51 43 51 L 21 51 C 19.895 51 19 50.105 19 49 L 19 33 C 19 31.895 19.895 31 21 31 z"></path>
              </svg>
            </div>
          )}
        </div>

        {/* Next arrow */}
        {imageIndex < totalImages - 1 && onNextClick && (
          <div className="absolute right-4 cursor-pointer p-2 bg-black/50 rounded-full z-10">
            <FaChevronRight className="text-2xl" onClick={onNextClick} />
          </div>
        )}
      </div>

      <div className="absolute bottom-0 left-0 right-0 z-50 flex justify-center mb-4">
        {isImageLocked
          ? <div className="flex flex-col items-center" onClick={onUnlockClick}>
            <div className="bg-white text-black p-4 rounded-full flex justify-center">
              <FaUnlock className="text-3xl" />
            </div>
            <button className="text-white text-s mt-2 font-bold">
              Unlock 100 more photos for $20
            </button>
          </div>
          : <div className="flex flex-col items-center" onClick={downloadImage}>
            <div className="bg-white text-black p-4 rounded-full flex justify-center">
              <FaDownload className="text-xl" />
            </div>
            <button className="text-white text-xs mt-2" >
              Download
            </button>
          </div>}
      </div>

    </div>
  );
};
