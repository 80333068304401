import React, { useState, useEffect } from "react";

interface LoaderProps {
  title: string;
  delay?: number;
  steps: string[];
}

const StepsLoader: React.FC<LoaderProps> = ({ title, steps, delay = 1500 }) => {
  const [currentStep, setCurrentStep] = useState<number>(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentStep((step) => step + 1);
    }, delay);
    return () => clearInterval(interval);
  }, []);

  return (
    <div className="flex flex-col items-center w-full">
      <div className="flex flex-col items-start p-6 bg-white text-gray-800 w-full rounded-lg border border-gray-300">
        {/* Header */}
        <h2 className="text-2xl font-bold mb-4">{title}</h2>

        {/* Progress Bar */}
        <div className="w-full bg-gray-200 rounded-full h-1.5 mb-6">
          <div
            className="h-1.5 rounded-full transition-all duration-700 bg-brand-primary"
            style={{
              width: `${((currentStep) / steps.length) * 100}%`,
            }}
          ></div>
        </div>

        {/* Steps */}
        <ul className="space-y-4">
          {steps.map((step, index) => {
            const isCompleted = index < currentStep;
            const isCurrent = index === currentStep;

            return (
              <li key={index} className="flex items-center">
                {/* Checkbox */}
                <div
                  className={`w-5 h-5 mr-3 flex items-center justify-center rounded-md border-2 transition-all duration-300 ${isCompleted
                    ? "border-brand-primary bg-brand-primary text-white"
                    : isCurrent
                      ? "border-brand-primary text-gray-300"
                      : "border-gray-300 text-gray-300"
                    }`}
                >
                  {isCompleted ? (
                    <span className="text-xs font-bold">✓</span>
                  ) : null}
                </div>

                {/* Step Text */}
                <span
                  className={`text-sm font-medium transition-all duration-300 ${isCompleted
                    ? "text-gray-600"
                    : isCurrent
                      ? "text-gray-600"
                      : "text-gray-300"
                    }`}
                >
                  {step}
                </span>
              </li>
            );
          })}
        </ul>
      </div>

      {/* Social Proof Section */}
      <div className="flex flex-col items-center mt-8">
        <div className="flex items-center gap-3">
          <div className="text-gray-300"></div>
          <div className="flex">
            {'★★★★★'.split('').map((star, i) => (
              <span key={i} className="text-yellow-400 text-xl">{star}</span>
            ))}
          </div>
          <div className="text-gray-300"></div>
        </div>
        <div className="text-xl font-medium text-gray-800 mt-2">
          24k+ happy users
        </div>
      </div>
    </div>
  );
};

export default StepsLoader;
