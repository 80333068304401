import React, { useCallback, useEffect, useState } from "react";
import { ProfileReviewWelcome } from "./ProfileReviewWelcome";
import { Wizard } from "../../components/wizard/Wizard";
import { WELCOME_PROFILE_REVIEWER_WIZARD_STEPS, WELCOME_PROFILE_REVIEWER_WIZARD_STEPS_SHORT } from "../../constants/wizard";
import { useWizardStore } from "../../stores/wizard";
import { useProfileStore } from "../../stores/profile";
import { ProgressBar } from "../../components/ProgressBar";
import { Helmet } from 'react-helmet-async';
import { WelcomeProfileReviewLanding } from './WelcomeProfileReviewLanding'
import { useUIStore } from "../../stores/ui";
import { WizardStepType } from "../../models/wizard";
import { ReviewedProfile } from "../../models/profile";
import { logEvent, useLogEvent } from "../../analytics";
import { useAuthStore } from "../../stores/auth";
import { usePostHog } from 'posthog-js/react'
import { useProfileReviewData } from "../profile-review/useProfileReviewData";

const loadingTitles = [
  "Analyzing your profile's first impressions...",
  "Diving deep into your photos...",
  "Evaluating photo quality and lighting...",
  "Scouting for social proof in pictures...",
  "Rearranging your photo lineup for maximum impact...",
  "Reviewing your bio",
  "Generating custom style suggestions…",
  "Summarizing our findings…",
  "Crafting your profile upgrade plan…",
  "Preparing to unveil the potential of your profile…"
];


export const ProfileReviewerWelcome = () => {
  const postHog = usePostHog()

  const {
    welcomeReviewerFiles,
    welcomeStep,
    setWelcomeStep,
    welcomeStepResults,
    setWelcomeStepResults,
    welcomeWizardComplete,
    setWelcomeWizardComplete,
    welcomeReviewStarted,
    setWelcomeReviewStarted,
    email,
  } = useWizardStore();

  const { isSubscribed } = useAuthStore()
  const { setError, } = useUIStore()

  const wizardSteps = postHog.getFeatureFlag('show-detailed-onboarding') === 'show'
    ? WELCOME_PROFILE_REVIEWER_WIZARD_STEPS : WELCOME_PROFILE_REVIEWER_WIZARD_STEPS_SHORT

  const {
    welcomeReviewedProfile,
    setWelcomeReviewedProfile,
    hasPaidForProfileReview,
    setHasPaidForProfileReview,
  } = useProfileStore();

  const { fetchReview } = useProfileReviewData()

  const [showReview, setShowReview] = useState(false)

  useLogEvent('start', 'profile_review')
  postHog?.capture('start_welcome')

  const fetchProfileReview = useCallback(async () => {
    if (welcomeReviewerFiles && welcomeReviewerFiles.length > 0) {
      try {
        logEvent('review_ai_start', 'profile_review')

        const data = await fetchReview(email, welcomeReviewerFiles)
        setWelcomeReviewedProfile(data as ReviewedProfile);
        setHasPaidForProfileReview(data.hasPaid);
      }
      catch (error) {
        setError(
          "There was an error reviewing your profile, please try again later."
        );
        const params = (error as Error)?.message ? {
          reason: (error as Error)?.message
        } : undefined

        logEvent('review_ai_fail', 'profile_review', email, params, 'error')
      };
    } else {
      setError(
        "There was an error retrieving your screenshots. Please go back and try again"
      );
      const params = {
        reason: 'no_screenshots'
      }
      logEvent('review_ai_fail', 'profile_review', email, params, 'error')

      localStorage.setItem(`welcome:step`, WizardStepType.UPLOAD_PHOTO);
      setWelcomeStep(WizardStepType.UPLOAD_PHOTO);
      setWelcomeWizardComplete(false);
    }
  }, [welcomeReviewerFiles, fetchReview, welcomeStepResults])

  const resetReview = () => {
    setWelcomeReviewedProfile(null)
    setShowReview(false)
  }

  // On component load, send request
  useEffect(() => {
    if (welcomeWizardComplete) {
      if (welcomeReviewedProfile != null) {
        setShowReview(true)
      } else {
        resetReview()
        fetchProfileReview();
      }
    }
  }, [welcomeWizardComplete, isSubscribed]);

  return (
    <div className="px-4">
      <Helmet>
        <meta name="description" content="Get an instant feedback for your dating profile. Trained by top matchmakers, powered by AI" />
      </Helmet>
      {welcomeReviewStarted ? (
        <div>
          <Wizard
            name="welcome"
            steps={wizardSteps}
            wizardComplete={welcomeWizardComplete}
            setWizardComplete={setWelcomeWizardComplete}
            step={welcomeStep}
            setStep={setWelcomeStep}
            stepResults={welcomeStepResults}
            setStepResult={setWelcomeStepResults}
            onBackPress={() => setWelcomeReviewStarted(false)}
            storeStep={true}
          >
            {showReview ? (
              <ProfileReviewWelcome
                hasPaid={hasPaidForProfileReview}
                setHasPaid={setHasPaidForProfileReview}
                onBackPress={resetReview}
              />
            ) : (
              <div className="flex flex-col items-center justify-center h-screen px-4">
                <div className="w-full max-w-lg text-center space-y-4 -mt-20 h-32">
                  <ProgressBar totalTime={150} complete={welcomeReviewedProfile != null} titles={loadingTitles} onCompleted={() => setShowReview(true)} />
                </div>
              </div>
            )}
          </Wizard>
        </div>
      ) : (
        <WelcomeProfileReviewLanding onGetStartedClick={() => setWelcomeReviewStarted(true)} />
      )}
    </div>
  );
};
