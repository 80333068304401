import { Elements } from "@stripe/react-stripe-js";
import { Appearance, StripeElementsOptionsMode, loadStripe } from "@stripe/stripe-js";
import { LOCKED_AIPHOTOS_PRICE } from "../../../pages/premium/paymentsConfig";
import LockedAIPhotosPaymentForm from "../LockedAIPhotosPaymentForm";

interface Props {
    returnUrl: string;
    redirectHandler?: () => void;
}

export const LockedAIPhotosPaywall = ({
    returnUrl,
    redirectHandler
}: Props) => {

    const stripePromise = loadStripe(import.meta.env.VITE_STRIPE_PUBLISHABLE_KEY);

    const appearance: Appearance = {
        theme: "stripe",
    };

    const options: StripeElementsOptionsMode = {
        mode: 'payment',
        amount: LOCKED_AIPHOTOS_PRICE * 100,
        currency: 'usd',
        appearance,
    };

    return (
        <div className="bg-white rounded-lg border border-black p-4 overflow-y-scroll mt-10">
            <div className="flex mb-4">
                <div className="w-1/2 font-semibold">
                    Unlock Photos
                </div>
                <div className="w-1/2 flex justify-end items-center">
                    {LOCKED_AIPHOTOS_PRICE}
                    &nbsp;once off
                </div>
            </div>
            <div className="mt-1">
                <Elements options={options} stripe={stripePromise}>
                    <LockedAIPhotosPaymentForm
                        returnUrl={returnUrl}
                        redirectHandler={redirectHandler}
                    />
                </Elements>
            </div>
        </div>
    );
};
