import { useEffect } from "react";
import { EventParams, logEvent } from "./analyticsUtils";
import { useWizardStore } from "../stores/wizard";

export const useLogEvent = (
  name: string,
  product: string | undefined = undefined,
  params: EventParams = {},
  category: string = "funnel"
) => {
  const { email } = useWizardStore();
  useEffect(() => {
    logEvent(name, product, email, params, category);
  }, []);
};
