import { Elements } from "@stripe/react-stripe-js";
import { Appearance, StripeElementsOptionsMode, loadStripe } from "@stripe/stripe-js";
import { useAuthStore } from "../../../stores/auth";
import AIPhotosPaymentForm from "../AIPhotosPaymentForm";
import { AIPhotosPlan } from "../../../constants/payments";
import { AIPHOTOS_BASIC_PRICE, AIPHOTOS_BASIC_PRICE_SUBSCRIBED, AIPHOTOS_PLUS_PRICE, AIPHOTOS_PLUS_PRICE_SUBSCRIBED, AIPHOTOS_PREMIUM_PRICE, AIPHOTOS_PREMIUM_PRICE_SUBSCRIBED } from "../../../pages/premium/paymentsConfig";
import { usePaymentStore } from "../../../stores/payment";
import { useState } from "react";

interface Props {
  returnUrl: string;
  redirectHandler?: () => void;
  selectedPlan: AIPhotosPlan;
}

export const AIPhotosPaywall = ({
  returnUrl,
  redirectHandler,
  selectedPlan
}: Props) => {
  const { isSubscribed } = useAuthStore();
  const { discountCode } = usePaymentStore();
  const [percentageOff, setPercentageOff] = useState<number | undefined>(undefined);

  const stripePromise = loadStripe(import.meta.env.VITE_STRIPE_PUBLISHABLE_KEY);

  const appearance: Appearance = {
    theme: "stripe",
  };

  const baseAmount = selectedPlan === AIPhotosPlan.Plus
    ? (isSubscribed ? AIPHOTOS_PLUS_PRICE_SUBSCRIBED * 100 : AIPHOTOS_PLUS_PRICE * 100)
    : selectedPlan === AIPhotosPlan.Premium
      ? (isSubscribed ? AIPHOTOS_PREMIUM_PRICE_SUBSCRIBED * 100 : AIPHOTOS_PREMIUM_PRICE * 100)
      : (isSubscribed ? AIPHOTOS_BASIC_PRICE_SUBSCRIBED * 100 : AIPHOTOS_BASIC_PRICE * 100);

  const discountedAmount = percentageOff
    ? baseAmount * (1 - percentageOff / 100)
    : baseAmount;

  const options: StripeElementsOptionsMode = {
    mode: 'payment',
    amount: baseAmount,
    currency: 'usd',
    appearance,
  };

  return (
    <div className="bg-white rounded-lg border border-black p-4 overflow-y-scroll mt-10">
      <div className="flex mb-4">
        <div className="w-1/2 font-semibold">
          {selectedPlan === AIPhotosPlan.Plus ? 'AI Enhanced Photos - Plus' : 'AI Enhanced Photos'}
        </div>
        <div className="w-1/2 flex justify-end items-center">
          <div className="flex flex-col items-end">
            {percentageOff && (
              <span className="text-red-500">
                ${(discountedAmount / 100).toFixed(2)}
              </span>
            )}
            <span className={percentageOff ? "line-through -mt-1" : ""}>
              ${(baseAmount / 100).toFixed(2)}
            </span>
          </div>
          &nbsp;once off
        </div>
      </div>
      <div className="mt-1">
        <Elements options={options} stripe={stripePromise}>
          <AIPhotosPaymentForm
            returnUrl={returnUrl}
            redirectHandler={redirectHandler}
            selectedPlan={selectedPlan}
            onPromoValidated={(percent_off) => setPercentageOff(percent_off)}
          />
        </Elements>
      </div>
    </div>
  );
};
